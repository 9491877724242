<template>
  <div class="home">
    <div class="home_title">
      <ul class="home_title_ul">
        <li class='home_title_ul_lis'>
          <img class="home_title_ul_li_logo" src="../../assets/logo.png" alt="">
        </li>
        <li class="home_title_ul_li" @click="GoToclassifyFun(0)">
          <p>{{ language === '0' ? '会员' : language === '1' ? 'Member' : 'สมาชิก' }}</p>
          <div class="xian"></div>
        </li>
        <li class="home_title_ul_li" style="margin:0 10px;" @click="GoToclassifyFun(1246)">
          <p>{{ language === '0' ? '特权' : language === '1' ? 'privilege' : 'สิทธิ์' }}</p>
          <div class="xian"></div>
        </li>
        <li class="home_title_ul_li" @click="GoToclassifyFun(1245)">
          <p>{{ language === '0' ? '复购' : language === '1' ? 'Repeat' : 'ทำซ้ำ' }}</p>
          <div class="xian"></div>
        </li>
      </ul>

      <div class="home_title_input">
        <i class="el-icon-search" @click.prevent="TosearchFun()"
          style="font-size: 30px;color: #ccc;margin-right: 40px;cursor: pointer;"></i>
      </div>

    </div>
    <div class="home_banner">
      <el-carousel class="home_banner_lb" indicator-position="none" height="500px">
        <el-carousel-item class="home_banner_lb_item" v-for="(item, index) in banner.data" :key="index">
          <el-image class="home_banner_img" :src="item.imgurl" fit="fill"></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="home_goods">
      <ul class="home_goods_ul" v-if="goods">
        <li class="home_goods_ul_li" v-for="(item, index) in goods" :key="index">
          <el-card class="Goods_card" style="height: 100%;">
            <div slot="header" class="clearfix">
              <h2>{{ item.title }}</h2>
            </div>
            <div class="Goods_card_box">
              <div v-for="(items, indexs) in item.goods_list" :key="indexs" class="Goods_card_list"
                @click="GoToFun(items.id)">
                <img :src="items.thumb" alt="">
                <div class="Goods_card_list_title">
                  {{ items.title }}
                </div>
                <div class="Goods_card_list_huode">
                  <div class="Goods_card_list_huode_Price">
                    Price${{ items.productprice }}
                  </div>
                  <div class="Goods_card_list_huode_PV">
                    PV {{ items.pv }}
                  </div>
                </div>
                <div class="Goods_card_list_price">
                  <div class="Goods_card_list_price_left">${{ items.marketprice }}</div>
                  <div class="Goods_card_list_price_right">
                    <i class="el-icon-plus" style="color: #fff;"></i>
                  </div>
                </div>
              </div>
            </div>
          </el-card>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { AjaxShowHomeFun, api_index_get_package_goods } from '@/api/home'
export default {
  data() {
    return {
      fixedsearch: '',
      banner: '',
      menu: '',
      picture: '',
      goods: '',
      language: '',
      searchText: ''//搜索
    }
  },
  created() {
    this.language = this.$store.state.language;
    this.showFun();
    this.api_index_get_package_goodsFun();
  },
  computed: {
    newData() {
      return this.$store.state.language
    }
  },
  watch: {

    newData(newVal, oldVal) {
      this.language = newVal;
    },

  },
  mounted() {
    // this.showFun();
  },
  methods: {
    GoToFun(id) {
      if (localStorage.getItem('openid') !== null && localStorage.getItem('openid') !== undefined) {
        this.$router.push({ name: 'Commodity_details', params: { id } });
      } else {
        this.$message.error('请先登录');
        this.$router.push({ name: 'Login' })
      }
    },
    GoToclassifyFun(path) {
      this.$router.push({ name: 'Classify', params: { cate: path } })
    },
    TosearchFun() {
      console.log('-----');
    },
    async showFun() {
      let res = await AjaxShowHomeFun();
      // console.log(res);
      this.fixedsearch = res[0];
      this.banner = res[1];
      this.menu = res[2];
      // console.log(this.menu);
      this.picture = res[3];
      // this.goods = res[4];
      // console.log(res[4]);
    },
    async api_index_get_package_goodsFun() {
      let data = await api_index_get_package_goods();
      this.goods = data;
      console.log(data);
    }
  }
}
</script>

<style lang="less" scoped>
.home {
  width: 100%;
  height: 100%;

  .home_title {
    width: 100%;
    height: 100px;
    background-color: #ffffff;
    position: sticky;
    top: 0;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .home_title_input {
      flex: 1;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .home_title_input_text {
        width: 300px;
        height: 40px;
        margin-right: 20px;
        border: 1px solid #fcfcfc;
      }
    }

    .home_title_ul {
      height: 100%;
      display: flex;
      align-items: center;

      .home_title_ul_li {
        min-width: 100px;
        text-align: center;
        cursor: pointer;
        height: 100px;
        line-height: 100px;
        position: relative;

        p {
          font-size: 20px;
          color: #666;
        }

        .xian {
          width: 0;
          height: 4px;
          background: #015198;
          border-radius: 4px;
          position: absolute;
          bottom: 10px;
        }

        .home_title_ul_li_img {
          width: 40px;
          height: 40px;
        }
      }

      .home_title_ul_lis {
        .home_title_ul_li_logo {
          height: 80px;
        }
      }

      .home_title_ul_li:hover p {
        color: #015198;
      }

      .home_title_ul_li:hover .xian {
        animation: scale-in-hor-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      }
    }

    @keyframes scale-in-hor-left {
      0% {
        width: 0;
        transform: scaleX(0);
        transform-origin: 0% 0%;
        opacity: 1;
      }

      100% {
        width: 100%;
        transform: scaleX(1);
        transform-origin: 0% 0%;
        opacity: 1;
      }
    }

  }

  .home_banner {
    width: 100%;
    height: 500px;
    margin-top: 30px;

    .home_banner_lb {
      width: 100%;
      height: 500px;

      .home_banner_lb_item {
        width: 100%;
        height: 500px;

        .el-carousel__container {
          width: 100% !important;
          height: 500px !important;
        }

        .home_banner_img {
          width: 100%;
          height: 500px;
        }
      }
    }
  }

  .home_goods {
    width: 100%;
    margin-top: 30px;

    .home_goods_ul {
      width: 100%;


      .home_goods_ul_li {

        width: 100%;
        margin: 10px 0;
        height: 400px;
        background: #fff;
        border-radius: 10px;
        display: flex;
        position: relative;

        .Goods_card {
          width: 100%;
          display: flex;
          flex-direction: column;


          .Goods_card_box {
            width: 100%;
            flex: 1;
            display: flex;
            // justify-content: space-around;

            .Goods_card_list {
              width: 200px;
              height: 300px;
              border-radius: 10px;
              transition-duration: 0.5s;
              cursor: pointer;
              border: #cfcfcf solid 1px;
              margin: 0 10px;
              overflow: hidden;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;

              img {
                width: 200px;
                height: 150px;
                // padding: 10px;
              }

              .Goods_card_list_title {
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                width: 80%;
                line-height: 20px;
                height: 40px;
                overflow: hidden;
              }

              .Goods_card_list_huode {
                display: flex;
                justify-content: space-between;
                width: 100%;
                padding: 12px;
                color: #0d6efd;
              }

              .Goods_card_list_price {
                height: 40px;
                width: 100%;
                display: flex;
                justify-content: space-between;

                .Goods_card_list_price_left {
                  color: #e21850;
                  padding-left: 10px;
                }

                .Goods_card_list_price_right {
                  width: 40px;
                  height: 40px;
                  background-color: #0d6efd;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  color: #fff;
                  border-radius: 12px 0 0 0;
                }
              }
            }

            .Goods_card_list:hover {
              border: none;
              transition-duration: 1s;
              box-shadow: 0px 0px 20px 0px #000;
            }

          }
        }



        .home_goods_ul_li_bottom {
          width: 300px;
          height: 30px;
          position: absolute;
          box-sizing: border-box;
          bottom: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 20px;

          .home_goods_ul_li_bottom_money {
            color: red;
          }
        }

        .home_goods_ul_li_img {
          width: 280px;
          height: 280px;
        }

        .home_goods_ul_li_title {
          padding: 20px;
          color: #333;

          img {
            width: 40px;
            height: 20px;
          }
        }
      }


    }
  }
}
</style>