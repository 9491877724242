<template>
  <div class="app_top">
    <div class="app_top_box">
      <div class="app_top_box_left"></div>
      <div class="app_top_box_right">


        <div v-if="openid !== '' && openid !== null" class="app_top_box_right_item">
          <div class="app_top_box_right_item_">
            {{ memebr.id }}
          </div>
        </div>

        <div class="app_top_box_right_item" @click="goTofFun('/')">
          <div class="app_top_box_right_item_">
            {{
          language === "0" ? "首页" : language === "1" ? "Home" : "หน้าแรก"
        }}
          </div>
        </div>

        <div v-if="openid !== '' && openid !== null" class="app_top_box_right_item wode">
          <div class="app_top_box_right_item_" @click="mineFun('/member')">
            {{ language === "0" ? "我的" : language === "1" ? "mine" : "ของฉัน" }}
          </div>
        </div>
        <div v-if="openid === '' || openid === null" class="app_top_box_right_item" @click="goTofFun('/login')">
          <div class="app_top_box_right_item_">
            {{ language === "0" ? "登录" : language === "1" ? "login" : "ล็อกอิน" }}
          </div>
        </div>
        <div class="app_top_box_right_item">
          <div class="app_top_box_right_item_">
            <el-dropdown trigger="click" @command="TablanguageFun($event)" :hide-timeout="150">
              <span class="el-dropdown-link app_top_box_right_item_">
                {{ language === "0" ? "语言" : language === "1" ? "language" : "ภาษา" }}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="0"> 中文 </el-dropdown-item>
                <el-dropdown-item command="1"> English </el-dropdown-item>
                <el-dropdown-item command="2"> ภาษาไทย </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div v-if="openid !== '' && openid !== null" class="app_top_box_right_item wode">
          <el-dropdown trigger="click" @command="mineFun" :hide-timeout="150">
            <span class="el-dropdown-link app_top_box_right_item_">
              {{ language === "0" ? "设置" : language === "1" ? "Settings" : "ตั้งค่า" }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="2">
                {{ language === "0" ? "收货地址" : language === "1" ? "Delivery address" : "ที่อยู่สำหรับการรับ" }}
              </el-dropdown-item>
              <el-dropdown-item command="1">
                {{ language === "0" ? "设置支付密码" : language === "1" ? "Set a payment password" : "ตั้งรหัสผ่านการชำระเงิน"
                }}
              </el-dropdown-item>
              <el-dropdown-item command="3">
                {{ language === "0" ? "设置登录密码" : language === "1" ? "Set login password" : "ตั้งรหัสผ่านการล็อกอิน"
                }}
              </el-dropdown-item>
              <el-dropdown-item command="0">
                {{ language === "0" ? "退出登录" : language === "1" ? "Log out" : "ออกจากการล็อกอิน" }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>



    <el-dialog fullscreen
      :title="language === '0' ? '地址管理' : language === '1' ? 'Address management' : 'การจัดการที่อยู่'"
      :visible.sync="dialogVisible" :before-close="handleClose">
      <div class="addressDiv">
        <add-address ref="dizhi"></add-address>
      </div>
    </el-dialog>
    <el-dialog width="60%" :title="language === '0' ? '支付密码' : language === '1' ? 'Payment password' : 'รหัสผ่าน'"
      :visible.sync="Set_passwordisshow" :before-close="handleClose">
      <div class="addressDiv">
        <Set_password v-if="Set_passwordisshow"></Set_password>
      </div>
    </el-dialog>
    <el-dialog width="60%"
      :title="language === '0' ? '登录密码' : language === '1' ? 'Login password' : 'รหัสผ่านการล็อกอิน'"
      :visible.sync="dialogVisiblelogin" :before-close="handleClose">
      <div class="addressDiv">
        <set-login-password @guanbi="handleClose" v-if="dialogVisiblelogin"></set-login-password>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { memberFun } from "@/api/member";
import addAddress from "./addAddress.vue";
import Set_password from "./Set_password.vue";
import setLoginPassword from './login_setpassword.vue'
export default {
  components: {
    addAddress,
    Set_password,
    setLoginPassword
  },
  computed: {
    newData() {
      return this.$store.state.language;
    },
  },
  watch: {
    dialogVisible(newVal, oldVal) {
      console.log(newVal);
      if (!newVal) {
        this.$refs.dizhi.guanbiFun();
      }
    },
    newData(newVal, oldVal) {
      this.language = newVal;
    },
  },
  data() {
    return {
      openid: "",
      memebr: "",
      dialogVisible: false,
      Set_passwordisshow: false,
      dialogVisiblelogin: false,
      language: "",
    };
  },
  created() {
    this.language = this.$store.state.language;
    this.openid = localStorage.getItem("openid");
    if (this.openid !== null) {
      this.showMemebr();
    }
    console.log(this.language);
  },
  mounted() { },
  methods: {
    mineFun(e) {
      console.log(e);
      if (e === '0') {
        this.exitLogin();
        return
      }
      if (e === '1') {
        this.Set_passwordisshow = true;
        return
      }
      if (e === '2') {
        this.dialogVisible = true;
        return
      }
      if (e === '3') {
        this.dialogVisiblelogin = true;
        return
      }


      this.goTofFun(e)


    },
    TablanguageFun(i) {
      this.$store.commit("languageFun", i);
      // setTimeout(() => {
      //   location.reload();
      // }, 10);
    },
    handleClose(done) {
      this.dialogVisible = false;
      this.Set_passwordisshow = false;
      this.dialogVisiblelogin = false;
    },
    exitLogin() {
      localStorage.clear();
      this.goTofFun("/login");
      this.openid = localStorage.getItem("openid");
    },
    goTofFun(path) {
      this.$router.push({ path });
    },
    async showMemebr() {
      let res = await memberFun(this.openid);
      this.memebr = res;
      console.log(res);
    },
  },
};
</script>
<style lang="less">
.app_top {
  width: 100%;
  background: #333;
  color: #000;

  .addressDiv {
    width: 100%;
    height: 570px;
    color: #000;
  }

  .app_top_box {
    width: 70%;
    height: 100%;
    margin: 0 auto;
    display: flex;

    .app_top_box_left {
      width: 50%;
      height: 100%;
    }

    .app_top_box_right {
      width: 50%;
      height: 100%;
      display: flex;
      // justify-content: space-between;
      justify-content: flex-end;
      // align-items: center;
      // padding: 0 20px;
      line-height: 40px;

      .wode {
        position: relative;

        .wode_xf {
          position: absolute;
          bottom: -250px;
          right: -30px;
          width: 400px;
          height: 250px;
          background: #333;
          z-index: 1000;
          display: none;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
          border-top: 1px solid #fff;

          .wode_xf_ul {
            width: 100%;
            height: 100%;
            padding: 30px;

            .wode_xf_ul_li {
              width: 100%;
              height: 50px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              border-radius: 10px;
              background: #ccc;
              padding: 10px;
              margin: 10px 0;

              .wode_xf_li_img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                border: 1px solid #333;
              }

              .wode_xf_ul_li_name {
                color: #333;
              }
            }
          }
        }
      }

      .wode:hover .wode_xf {
        display: block;
      }

      .app_top_box_right_item {
        cursor: pointer;
        margin: 0 20px;
        font-size: 12px;

        .app_top_box_right_item_ {
          color: rgba(255, 255, 255, 0.6);

          .el-dropdown-link {
            cursor: pointer;
            color: rgba(255, 255, 255, 0.6);
          }

          .el-icon-arrow-down {
            font-size: 12px;
          }
        }
      }

      .app_top_box_right_item:hover .app_top_box_right_item_ {
        color: #fff;
      }
    }
  }
}
</style>