import { render, staticRenderFns } from "./appBottom.vue?vue&type=template&id=5e6ec933"
import script from "./appBottom.vue?vue&type=script&lang=js"
export * from "./appBottom.vue?vue&type=script&lang=js"
import style0 from "./appBottom.vue?vue&type=style&index=0&id=5e6ec933&prod&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports