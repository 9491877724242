import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    language: '0'
  },
  getters: {
  },
  mutations: {
    languageFun(state, type) {
      state.language = type;
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState({
    storage: window.sessionStorage, // 或者localStorage
  })]
})
