<template>
  <div id="app">
    <app-top class="app_top"></app-top>
    <router-view class="app_center" />
  </div>
</template>
<script>
import appTop from '@/components/appTop.vue';
import appBottom from '@/components/appBottom.vue';
export default {
  components: {
    appBottom,
    appTop
  },
  data() {
    return {

    }
  },
  created() {

  },
  mounted() {
    document.body.style.overflowX = 'hidden';
  }
}
</script>
<style lang="less">
div {
  box-sizing: border-box;
}

#app {
  width: 100vw;
  display: flex;
  flex-direction: column;

  .app_top {
    width: 100vw;
    height: 40px;
  }

  .app_center {
    width: 1200px;
    min-height: 90vh;
    margin: 0 auto;
  }

  .app_bottom {
    width: 100vw;
    height: 188px;
  }
}
</style>
