<template>
  <div class="addAddress">
    <div class="addAddress_add">
      <el-form ref="form" label-position="left" :model="form">
        <el-form-item :label="language === '0' ? '收货人' : language === '1' ? 'consignee' : 'คนรับสินค้า'">
          <el-input v-model="form['addressdata[realname]']"
            :placeholder="language === '0' ? '请填写收货人' : language === '1' ? 'Please fill in the consignee' : 'คนรับสินค้า'"></el-input>
        </el-form-item>
        <el-form-item :label="language === '0' ? '联系电话' : language === '1' ? 'Contact number' : 'ติดต่อ'">
          <el-input
            :placeholder="language === '0' ? '请填写联系电话' : language === '1' ? 'Please fill in the contact number' : 'กรุณากรอกเบอร์ติดต่อ'"
            v-model="form['addressdata[mobile]']"></el-input>
        </el-form-item>
        <el-form-item :label="language === '0' ? '所在地区' : language === '1' ? 'Local area' : 'พื้น ที่'">
          <el-select v-model="country"
            :placeholder="language === '0' ? '请选择所在国家' : language === '1' ? 'Please select your country' : 'โปรดเลือกประเทศ ที่อยู่'"
            @change="countryFun" style="width: 100%;">
            <el-option :label="data.areaName" v-for="( data, index ) in  countryList " :key="index"
              :value="data.areaCode"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="language === '0' ? '详细地址' : language === '1' ? 'Detailed address' : 'รายละเอียด ที่อยู่'">
          <el-input
            :placeholder="language === '0' ? '请填写详细地址' : language === '1' ? 'Please fill in the full address' : 'กรุณากรอก ที่อยู่โดยละเอียด'"
            v-model="form['addressdata[areas]']"></el-input>
        </el-form-item>


        <el-form-item v-if="country === '53309'"
          :label="language === '0' ? '邮编' : language === '1' ? 'Zip code' : 'จดหมาย'">
          <el-input
            :placeholder="language === '0' ? '请填写邮编' : language === '1' ? 'Please fill in the postcode' : 'กรุณากรอกทางไปรษณีย์'"
            v-model="form['addressdata[zipcode]']"></el-input>
        </el-form-item>



        <el-button type="primary" style="width: 100%;" @click="member_address_submitFun">{{ language === '0' ?
        '添加/修改' : language === '1' ? 'Add/modify' : 'เพิ่ม/แก้ไข' }}</el-button>
      </el-form>
    </div>
    <el-dialog width="50%" lock-scroll :show-close="false" :close-on-click-modal="false" :title="language === '0' ?
        '选择所在地区' : language === '1' ? 'Select location' : 'เลือกพื้น ที่'" :visible.sync="innerVisible" append-to-body>
      <div v-if="country === '0'">
        <el-select v-model="sheng" :placeholder="language === '0' ?
        '请选择所在省份' : language === '1' ? 'Please select your province' : 'โปรดเลือกจังหวัด'
        " @change="shengFun">
          <el-option :label="data.label" v-for="(  data, index  ) in   province  " :key="index"
            :value="index"></el-option>
        </el-select>
        <el-select v-if="city !== ''" v-model="shi" :placeholder="language === '0' ?
        '请选择所在城市' : language === '1' ? 'Please select your city' : 'เลือกเมืองไหนคะ'
        " @change="shiFun">
          <el-option :label="data.label" v-for="(  data, index  ) in   city  " :key="index" :value="index"></el-option>
        </el-select>
        <el-select v-if="shi !== ''" v-model="qu" :placeholder="language === '0' ?
        '请选择所在区域' : language === '1' ? 'Please select your region' : 'โปรดเลือกพื้น ที่'
        " @change="quFun">
          <el-option :label="data.label" v-for="(  data, index  ) in   area  " :key="index" :value="index"></el-option>
        </el-select>
      </div>


      <div v-else>
        <el-select v-if="province" v-model="sheng" :placeholder="language === '0' ?
        '请选择所在省份' : language === '1' ? 'Please select your province' : 'โปรดเลือกจังหวัด'
        " @change="shengFun">
          <el-option :label="data.areaName" v-for="(  data, index  ) in   province  " :key="index"
            :value="data.areaCode"></el-option>
        </el-select>
        <el-select v-if="city.length !== 0" v-model="shi" :placeholder="language === '0' ?
        '请选择所在城市' : language === '1' ? 'Please select your city' : 'เลือกเมืองไหนคะ'
        " @change="shiFun">
          <el-option :label="data.areaName" v-for="(  data, index  ) in   city  " :key="index"
            :value="data.areaCode"></el-option>
        </el-select>
        <el-select v-if="area.length !== 0" v-model="qu" :placeholder="language === '0' ?
        '请选择所在区域' : language === '1' ? 'Please select your region' : 'โปรดเลือกพื้น ที่'
        " @change="quFun">
          <el-option :label="data.areaName" v-for="(  data, index  ) in   area  " :key="index"
            :value="data.areaCode"></el-option>
        </el-select>
      </div>





      <div slot="footer" class="dialog-footer">
        <el-button @click="quxiaoFun">
          {{ language === '0' ? '取消' : language === '1' ? 'Cancel' : 'ยกเลิก' }}
        </el-button>
        <el-button type="primary" @click="hechengsuozaidiFun">
          {{ language === '0' ? '确定' : language === '1' ? 'OK' : 'แน่ใจ' }}
        </el-button>
      </div>
    </el-dialog>
    <div class="addAddress_list">
      <el-table :data="tableData" ref="singleTable" height="100%" border
        style="width: 100%;font-size: 14px;text-align: center;" @current-change="handleCurrentChange">
        <el-table-column prop="realname"
          :label="language === '0' ? '姓名' : language === '1' ? 'Name' : 'ชื่อ'"></el-table-column>
        <el-table-column prop="mobile"
          :label="language === '0' ? '电话' : language === '1' ? 'telephone' : 'โทรศัพท์'"></el-table-column>
        <el-table-column prop="zipcode"
          :label="language === '0' ? '邮编' : language === '1' ? 'Zip code' : 'จดหมาย'"></el-table-column>
        <el-table-column
          :label="language === '0' ? '详细地址' : language === '1' ? 'Detailed address' : 'รายละเอียด ที่อยู่'">
          <template slot-scope="scope">
            <div>
              {{ scope.row.country_code === '0' ? scope.row.country + ' - ' + scope.row.address + ' - ' +
        scope.row.province
        : scope.row.country + ' - ' + scope.row.province + ' - ' + scope.row.address }}
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" :label="language === '0' ? '操作' : language === '1' ? 'Controls' : 'การกระทำ'">
          <template slot-scope="scope">
            <div style="display: flex;">
              <div class="scope_button" @click="bianjiFun(scope.row)">
                {{ language === '0' ? '编辑' : language === '1' ? 'EDITOR' : 'แก้ไข' }}
              </div>
              <div class="scope_button" style="margin:0 20px;" @click="member_address_deleteFun(scope.row.id)">
                {{ language === '0' ? '删除' : language === '1' ? 'delete' : 'ลบ' }}
              </div>
              <div class="scope_button" @click="member_address_setdefaultFun(scope.row.id)">
                {{ language === '0' ? '设为默认' : language === '1' ? 'Set as default' : 'ตั้งเป็นค่าปริยาย' }}
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import province from '@/assets/diqu/province'; //省份
import city from '@/assets/diqu/city';//市级
import area from '@/assets/diqu/area';//区县
import { member_address_submit, member_address, member_address_delete, api_index_get_country, api_index_get_child_address, member_address_setdefault, member_address_post } from '@/api/address';

export default {
  props: {
    changeID: ''
  },
  data() {
    return {
      innerVisible: false,
      form: {
        'id': '',
        'openid': '',
        'addressdata[country]': '',
        'addressdata[realname]': '',
        'addressdata[mobile]': '',
        'addressdata[address]': '',
        'addressdata[areas]': '',
        'addressdata[country_code]': '',
        'addressdata[zipcode]': ''
      },

      country: '',
      sheng: '',
      shi: '',
      qu: '',

      province: '',
      city: '',
      area: '',
      countryList: [],



      tableData: [],
      currentRow: null,


      language: '',

      shengname: '',
      shiname: '',
      quname: ''

    };
  },
  created() {
    this.language = this.$store.state.language;
    this.openid = localStorage.getItem('openid');
    this.form["openid"] = localStorage.getItem('openid');
    console.log(this.form["openid"]);
    this.member_addressFun();
    this.api_index_get_countryFun();

    if (this.changeID) {
      this.member_address_postFun({ id: this.changeID, openid: this.openid });
    }
  },
  computed: {
    newData() {
      return this.$store.state.language
    }
  },
  watch: {
    newData(newVal, oldVal) {
      this.language = newVal;
    },
  },
  methods: {

    async member_address_postFun(res) {

      let row = await member_address_post(res);
      this.form['id'] = row.id;
      this.form['addressdata[address]'] = row.address;
      this.form['addressdata[realname]'] = row.realname;
      this.form['addressdata[areas]'] = row.province + ' ' + row.city + ' ' + row.area;
      this.form['addressdata[country]'] = row.country
      this.form['addressdata[mobile]'] = row.mobile;
      this.form['addressdata[zipcode]'] = row.zipcode;
      this.form['addressdata[country_code]'] = row.country_code;
      this.country = row.country_code;

    },



    async member_address_setdefaultFun(id) {
      let up = {
        openid: this.openid,
        id
      }
      let data = await member_address_setdefault(up);
      console.log(data);
      if (data.status === 1) {
        this.member_addressFun();
        this.$message.success(`${this.language === '0' ? '设置完成' : this.language === '1' ? 'Setup complete' : 'ตั้งค่าเสร็จสมบูรณ์'}`)
      }

    },
    async api_index_get_child_addressFun(areaCode) {
      let up = {
        openid: this.openid,
        areaCode
      }
      let data = await api_index_get_child_address(up);
      return data.address_data;
    },
    async api_index_get_countryFun() {
      let data = await api_index_get_country(this.form.openid);
      this.countryList = data.country_data;
      this.countryList.unshift({
        areaCode: "0",
        areaName: "中国"
      });
    },
    countryFun(e) {
      this.area = '';
      this.province = '';
      this.city = '';
      this.form['addressdata[country]'] = this.namechuliFun(this.countryList, e);
      console.log(this.form['addressdata[country]']);
      this.form['addressdata[country_code]'] = e;
      if (e === '0') {
        this.province = province;
      } else {
        let data = this.api_index_get_child_addressFun(e);
        data.then(res => {
          this.province = res
        })
      }
      this.innerVisible = true;
    },
    namechuliFun(arr, e) {
      console.log(arr, e);
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].areaCode === e) {
          return arr[i].areaName;
        }
      }
    },
    bianjiFun(row) {
      this.form['addressdata[address]'] = row.address;
      this.form['addressdata[realname]'] = row.realname;
      this.form['addressdata[areas]'] = row.province + ' ' + row.city + ' ' + row.area;
      this.form['addressdata[country]'] = row.country
      this.form['addressdata[mobile]'] = row.mobile;
      this.form['id'] = row.id;
      this.country = row.country_code;
      this.form['addressdata[zipcode]'] = row.zipcode;
      this.form['addressdata[country_code]'] = row.country_code;
    },
    handleCurrentChange(val) {
      this.currentRow = val;
    },
    setCurrent(row) {
      this.$refs.singleTable.setCurrentRow(row);
    },
    async member_address_deleteFun(id) {
      let data = await member_address_delete({ openid: this.form['openid'], id })
      if (data.status === 1) {
        this.member_addressFun();
        this.$message.success(`${this.language === '0' ? '已删除' : this.language === '1' ? 'Already removed' : 'ลบแล้ว'}`)
      }
    },
    handleClick(index, row) {
      console.log(index, row);
    },
    handleDelete(index, row) {
      console.log(index, row);
    },
    async member_addressFun() {
      let data = await member_address(this.form["openid"]);
      this.tableData = data;
      console.log(this.tableData);
    },
    async member_address_submitFun() {
      if (this.form['addressdata[realname]'], this.form['addressdata[address]'], this.form['addressdata[areas]'], this.form['addressdata[mobile]'] === '') {
        this.$message.error('有值未填写！！！');
        return
      }
      let data = await member_address_submit(this.form);
      this.guanbiFun();
      this.member_addressFun();
    },
    guanbiFun() {
      this.area = '';
      this.province = '';
      this.city = '';
      this.country = '';
      this.form['addressdata[address]'] = '';
      this.form['addressdata[realname]'] = '';
      this.form['addressdata[areas]'] = '';
      this.form['addressdata[street]'] = '';
      this.form['addressdata[mobile]'] = '';
      this.form['zipcode'] = '';
      this.form['id'] = '';
      this.sheng = '';
      this.shi = '';
      this.qu = '';
    },
    quxiaoFun() {
      this.innerVisible = false;
      this.form['addressdata[areas]'] = '';
      this.country = '';
      this.sheng = '';
      this.shi = '';
      this.qu = '';
    },
    shengFun(e) {
      this.shi = '';
      let _this = this;
      if (this.country === '0') {
        this.city = city[e];
      } else {
        this.api_index_get_child_addressFun(e).then(res => {
          _this.city = res;
          _this.shengname = _this.namechuliFun(_this.province, e);
          console.log(_this.shengname);
        })
      }
    },
    shiFun(e) {
      this.qu = '';
      let _this = this;
      if (this.country === '0') {
        this.area = area[this.sheng][e];
      } else {
        this.api_index_get_child_addressFun(e).then(res => {
          _this.area = res;
          _this.shiname = _this.namechuliFun(_this.city, e);
          console.log(_this.shiname);
        })
      }
    },
    quFun(e) {
      console.log(e);
      if (this.country === '0') {
        this.area = area[this.sheng][e];
      } else {
        this.quname = this.namechuliFun(this.area, e);
      }
    },
    hechengsuozaidiFun() {
      setTimeout(() => {
        if (this.country === '0') {
          this.form['addressdata[address]'] = `${this.sheng !== '' ? province[this.sheng].label : ''}${this.shi !== '' ? ' - ' : ''}${this.shi !== '' ? city[this.sheng][this.shi].label : ''}${this.qu !== '' ? ' - ' : ''}${this.qu !== '' ? area[this.sheng][this.shi][this.qu].label : ''}`;
        } else {
          this.form['addressdata[address]'] = `${this.quname !== '' ? this.quname : ''} ${this.quname !== '' ? ' - ' : ''}${this.shiname !== '' ? this.shiname : ''}${this.shiname !== '' ? ' - ' : ''}${this.shengname !== '' ? this.shengname : ''}`
        }
        console.log(this.form['addressdata[address]']);
      }, 10)
      this.innerVisible = false;
    }
  }
};
</script>
<style lang="less" scoped>
.addAddress {
  width: 100%;
  height: 100%;
  display: flex;


  .flex {
    display: flex;
    align-items: center;
  }

  .addAddress_add {
    width: 600px;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
  }

  .addAddress_list {
    flex: 1;
    height: 100%;
    font-size: 12px;
  }

  .scope_button {
    cursor: pointer;
  }

  .scope_button:hover {
    color: #67C23A;
  }
}
</style>