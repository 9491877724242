<template>
    <div class="Set_password">
        <el-form ref="form" :model="form">
            <el-form-item v-if="isset_paypwd" prop="oldpaypwd"
                :label="language === '0' ? '原密码' : language === '1' ? 'Old password' : 'รหัสผ่านดั้งเดิม'"
                :rules="rules">
                <el-input v-model.number="form.oldpaypwd" show-password maxlength="6" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item prop="paypwd"
                :label="language === '0' ? '支付密码' : language === '1' ? 'Payment password' : 'รหัสผ่าน'" :rules="rules">
                <el-input v-model.number="form.paypwd" show-password maxlength="6" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item prop="paypwd1"
                :label="language === '0' ? '确认密码' : language === '1' ? 'Confirm password' : 'ยืนยันรหัสผ่าน'"
                :rules="rules">
                <el-input v-model.number="form.paypwd1" show-password maxlength="6" autocomplete="off"></el-input>
            </el-form-item>
            <el-button type="primary" @click="member_paypwd_faceFun">
                {{ language === '0' ? '设置密码' : language === '1' ? 'Set a password' : 'ตั้งรหัสผ่าน' }}
            </el-button>
        </el-form>
    </div>
</template>
<script>
import { member_paypwd_face, api_index_paypwd_check } from '@/api/member'
export default {
    data() {
        let language = this.$store.state.language
        return {
            openid: "",
            language,
            form: {
                paypwd: '',
                paypwd1: '',
                oldpaypwd: ''
            },
            age: '',
            rules: [
                { required: true, message: `${language === '0' ? '密码不能为空' : language === '1' ? 'The password cannot be empty' : 'รหัสผ่านไม่สามารถว่า งได้'}` },
                { type: 'number', message: `${language === '0' ? '支付密码必须为数字' : language === '1' ? 'The payment password must be a number' : 'รหัสผ่านต้องเป็นตัวเลข'}` }
            ],
            isset_paypwd: ''
        }
    },
    created() {
        this.openid = localStorage.getItem("openid");
        this.api_index_paypwd_checkFun();
    },
    computed: {
        newData() {
            return this.$store.state.language
        }
    },
    watch: {
        newData(newVal, oldVal) {
            this.language = newVal;
        },

    },
    methods: {
        async api_index_paypwd_checkFun() {
            let data = await api_index_paypwd_check(this.openid);
            this.isset_paypwd = data.isset_paypwd;
        },
        async member_paypwd_faceFun() {
            let up = {
                paypwd: this.form.paypwd,
                paypwd1: this.form.paypwd1,
                oldpaypwd: this.form.oldpaypwd,
                openid: this.openid
            }
            let data = await member_paypwd_face(up);
            if (data.status === 1) {
                this.$message.success(data.result.message);
            } else {
                this.$message.error(data.result.message);
            }
        }
    }
}

</script>
<style lang="less" scoped></style>