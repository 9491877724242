<template>
    <div class="Set_password">
        <el-form ref="form" :model="form">
            <el-form-item prop="oldpaypwd"
                :label="language === '0' ? '原密码' : language === '1' ? 'Old password' : 'รหัสผ่านดั้งเดิม'">
                <el-input v-model.number="form.oldpaypwd" show-password maxlength="6" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item prop="paypwd"
                :label="language === '0' ? '新密码' : language === '1' ? 'New password' : 'รหัสผ่านใหม่'">
                <el-input v-model.number="form.paypwd" show-password maxlength="6" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item prop="paypwd1"
                :label="language === '0' ? '确认密码' : language === '1' ? 'Confirm password' : 'ยืนยันรหัสผ่าน'">
                <el-input v-model.number="form.paypwd1" show-password maxlength="6" autocomplete="off"></el-input>
            </el-form-item>
            <el-button type="primary" @click="member_paypwd_faceFun">
                {{ language === '0' ? '设置密码' : language === '1' ? 'Set a password' : 'ตั้งรหัสผ่าน' }}
            </el-button>
        </el-form>
    </div>
</template>
<script>
import { member_changepassword } from '@/api/member'
export default {
    data() {
        let language = this.$store.state.language
        return {
            openid: "",
            language,
            form: {
                paypwd: '',
                paypwd1: '',
                oldpaypwd: ''
            },
            age: '',
            isset_paypwd: ''
        }
    },
    created() {
        this.openid = localStorage.getItem("openid");
    },
    computed: {
        newData() {
            return this.$store.state.language
        }
    },
    watch: {
        newData(newVal, oldVal) {
            this.language = newVal;
        },

    },
    methods: {
        exitLogin() {
            localStorage.clear();
            this.goTofFun("/login");
            this.openid = localStorage.getItem("openid");
            setTimeout(() => {
                location.reload();
            }, 100)
        },
        goTofFun(path) {
            this.$router.push({ path });
        },
        async member_paypwd_faceFun() {
            let up = {
                new_password: this.form.paypwd,
                new_password_confirm: this.form.paypwd1,
                old_password: this.form.oldpaypwd,
                openid: this.openid
            }
            let data = await member_changepassword(up);
            if (data.status === 1) {
                this.$message.success(data.result.message);
                this.$emit('guanbi');
                setTimeout(() => {
                    this.exitLogin();
                }, 500)
            } else {
                this.$message.error(data.result.message);
            }
        }
    }
}

</script>
<style lang="less" scoped></style>