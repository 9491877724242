import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router'
import '../src/assets/chushi.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { Message } from 'element-ui';



const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
// import VueLazyload from 'vue-lazyload'
// Vue.use(VueLazyload)
Vue.use(VueRouter);
Vue.prototype.$Message = Message;
// 基准大小
const baseSize = 16;
// 设置 rem 函数
function setRem() {
  // 当前页面宽度相对于 1920宽的缩放比例，可根据自己需要修改。
  const scale = document.documentElement.clientWidth / 1920;
  // 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
  document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + 'px';
}
// 初始化
setRem()
// 改变窗口大小时重新设置 rem
window.onresize = function () {
  setRem()
}

Vue.config.productionTip = false;
Vue.use(ElementUI);
import router from './router';
import store from './store';
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
