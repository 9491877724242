import request from './request';

//添加地址 member.address.submit
export function member_address_submit(data) {
    return request('member.address.submit&app=1', 'post', data)
}
//获取地址列表 member.address
export function member_address(openid) {
    return request('member.address&app=1', 'post', { openid })
}

//地址列表删除 member.address.delete
export function member_address_delete(data) {
    return request('member.address.delete&app=1', 'post', data)
}
//设置默认地址 member.address.setdefault
export function member_address_setdefault(data) {
    return request('member.address.setdefault&app=1', 'post', data)
}
//获取国家 api.index.get_country
export function api_index_get_country(openid) {
    return request('api.index.get_country&app=1', 'post', { openid })
}

//获取其他国家信息 api.index.get_child_address
export function api_index_get_child_address(data) {
    return request('api.index.get_child_address&app=1', 'post', data)
}

//获取单条收货地址 member.address.post
export function member_address_post(data) {
    return request('member.address.post&app=1', 'post', data)
}