import request from './request';

//获取用户信息
export function memberFun(id) {
  return request('member&app=1', 'post', { openid: id })
}
//获取订单信息 order.get_list
export function order_get_list(data) {
  return request('order.get_list&app=1', 'post', data)
}

//确认收货 order.op.finish
export function order_op_finish(data) {
  return request('order.op.finish&app=1', 'post', data)
}

//设置支付密码 member.paypwd.face
export function member_paypwd_face(data) {
  return request('member.paypwd.face&app=1', 'post', data)
}
//此账号是否设置过密码 api.index.paypwd_check
export function api_index_paypwd_check(openid) {
  return request('api.index.paypwd_check&app=1', 'post', { openid })
}

//获取用户余额 api.index.member_hz
export function api_index_member_hz(openid) {
  return request('api.index.member_hz&app=1', 'post', { openid })
}
//获取转增账户的用户名 api.index.get_nickname
export function api_index_get_nickname(ID) {
  return request('api.index.get_nickname&app=1', 'post', { ID })
}

//转增积分 api.index.credit2_mutual_rotation
export function api_index_credit2_mutual_rotation(data) {
  return request('api.index.credit2_mutual_rotation&app=1', 'post', data)
}

//明细 
export function detail(url, data) {
  return request(`${url}&app=1`, 'post', data)
}

//获取银行信息 api.index.bank_info
export function api_index_bank_info(openid) {
  return request('api.index.bank_info&app=1', 'post', { openid })
}

//提交充值 api.index.recharge
export function api_index_recharge(data) {
  return request('api.index.recharge&app=1', 'post', data)
}

//信托申报api.index.report_xt
export function api_index_report_xt(data) {
  return request('api.index.report_xt&app=1', 'post', data)
}

//获取邀请二维码 commission.qrcode.shareposter&app=1
export function commission_qrcode_shareposter(data) {
  return request('commission.qrcode.shareposter&app=1', 'post', data)
}

//获取用户网体 api.index.get_tree 
export function api_index_get_tree(openid) {
  return request('api.index.get_tree&app=1', 'post', { openid })
}

//搜索用户网体 api.index.serch_tree
export function api_index_serch_tree(data) {
  return request('api.index.serch_tree&app=1', 'post', data)
}
//修改登录密码 member.changepassword
export function member_changepassword(data) {
  return request('member.changepassword&app=1', 'post', data)
}

//获取粉丝信息 api.index.my_fans
export function api_index_my_fans(openid) {
  return request('api.index.my_fans&app=1', 'post', { openid })
}

//获取团队信息 api.index.my_city
export function api_index_my_city(data) {
  return request('api.index.my_city&app=1', 'post', data)
}

//获取国家 api.index.get_countrys
export function api_index_get_countrys() {
  return request('api.index.get_countrys&app=1', 'post')
}

//注册新用户 api.index.register_for_pc
export function api_index_register(data) {
  return request('api.index.register_for_pc&app=1', 'post', data)
}