<template>
  <div class="app_bottom">
    <div class="app_bottom_box">

      <div class="lianxi">
        <p>
          江苏省无锡市梁溪区广益路五洲国际银河城南楼2401室
        </p>
        <p>
          邮箱：749866090@qq.com
        </p>
      </div>
      <div class="lianxi">
        <p>
          Copyright ©2024 壹生贰互联网科技无锡有限公司 版权所有
        </p>
        <p>
          手机号：18751577220
        </p>
      </div>
      <div class="lianxi">
        <p><a href="https://beian.miit.gov.cn/#/Integrated/index">苏ICP备2024063382号-1</a></p>
        <p>
          微信号：x18751577220
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  created() {



  },
  methods: {


  }
}

</script>
<style lang="less">
.app_bottom {
  width: 100%;
  background-color: #333;

  .app_bottom_box {
    width: 80vw;
    height: 10vh;
    margin: 0 auto;
    padding: 30px 0 0 0;

    .lianxi {
      width: 80%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
    }

    p {
      width: 80%;
      margin: 0 auto;
      // text-align: center;
      margin-top: 20px;
      color: rgba(255, 255, 255, 0.6);
      font-size: 14px;

      // margin: 20px 0;
      a {
        color: rgba(255, 255, 255, 0.6);
      }
    }

    p a:hover {
      color: #fff;
    }
  }
}
</style>