import axios from 'axios';
import { showLoading, hideLoading } from './Loading';
// const baseUrl = 'http://c.cmcpint.top/app/index.php?i=2&c=entry&m=ewei_shopv2&do=mobile&r=';
const baseUrl = 'https://a.cmcpint.top/app/index.php?i=2&c=entry&m=ewei_shopv2&do=mobile&r=';


const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };



const request = (url, text, data = null) => {
  showLoading();
  let method = text.toUpperCase();
  return new Promise((resolve, reject) => {
    if (method === 'GET') {
      console.log('开始请求');
      axios({
        method: method,
        url: baseUrl + url,
        params: data,
        headers,
      }).then(res => {
        console.log('请求成功');
        resolve(res.data);
        hideLoading();
      })
        .catch(err => {
          reject(err.data.message); //失败
          hideLoading();
        });
    } else if (method === 'POST') {
      axios({
        method: method,
        url: baseUrl + url,
        data: data,
        headers,
      }).then(res => {
        console.log('请求成功');
        resolve(res.data);
        hideLoading()
      })
        .catch(err => {
          reject(err.data.message); //失败
          hideLoading();
        });
    }
  })


}
export default request;
