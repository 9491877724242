import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/index.vue')
  },
  {
    path: '/homeParticulars',
    name: 'HomeParticulars',
    component: () => import('../views/homeParticulars/index.vue')
  },
  {
    path: '/member',
    name: 'Member',
    component: () => import('../views/member/index.vue')
  },
  // {
  //   path: '/myOrder',
  //   name: 'MyOrder',
  //   component: () => import('../views/member/my_order.vue')
  // },
  {
    path: '/querendingdan:a',
    name: 'Querendingdan',
    component: () => import('../views/querendingdans/index.vue')
  },
  {
    path: '/commodity_details/:id',
    name: 'Commodity_details',
    component: () => import('../views/commodity_details/index.vue')
  },
  {
    path: '/payment/:e',
    name: 'Payment',
    component: () => import('../views/payment/index.vue')
  },
  {
    path: '/classify/:cate',
    name: 'Classify',
    component: () => import('../views/classify/index.vue')
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
